import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Technology from "../../../components/ProductDetails/netversys/Technology"
import Seo from "../../../components/App/Seo"

const TechnologyPage = () => {
  return (
    <Layout>
      <Seo title={"Fortschrittliche Technologie ergibt gute Prozesse im Versand"}
           description={"Technologisch seit Jahren an der Spitze im " +
           "Bereich der Versandlogistik. Wir waren schon browserbasiert als alle " +
           "anderen noch nicht an Browser dachten."}
           image={"/images/og/netversys-main-og.png"}>
        <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://logentis.de"
                },{
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Versandsoftware",
                  "item": "https://logentis.de/versandsoftware/"
                },{
                  "@type": "ListItem",
                  "position": 3,
                  "name": "Technologie",
                  "item": "https://logentis.de/versandsoftware/netversys/technologie/"
                }]
              }
              `}
        </script>
      </Seo>
      <Navbar />
      <Technology />
      <Footer />
    </Layout>
  )
}

export default TechnologyPage
